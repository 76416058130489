/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommunicationCategory {
  GENERAL = "GENERAL",
  ORDERS = "ORDERS",
  PAYMENTS = "PAYMENTS",
  PRODUCTS = "PRODUCTS",
  PROFILE = "PROFILE",
}

export enum ConfigurationKey {
  COMMISSION_MAX_COEF_1 = "COMMISSION_MAX_COEF_1",
  COMMISSION_MAX_COEF_2 = "COMMISSION_MAX_COEF_2",
  COMMISSION_MAX_COEF_3 = "COMMISSION_MAX_COEF_3",
  COMMISSION_MAX_INTERCEPT = "COMMISSION_MAX_INTERCEPT",
  COUNTRY_CODE = "COUNTRY_CODE",
  COUNTRY_NAME = "COUNTRY_NAME",
  CURRENCY_PRECISION = "CURRENCY_PRECISION",
  DEFAULT_IVA = "DEFAULT_IVA",
  MONEY_CODE = "MONEY_CODE",
}

export enum PaymentStatus {
  NOT_PAID = "NOT_PAID",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PENDING = "PENDING",
}

export enum PersonType {
  FISICA = "FISICA",
  LEGAL = "LEGAL",
  MORAL = "MORAL",
  NATURAL = "NATURAL",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TaxRegime {
  GREAT_CONTRIBUTOR = "GREAT_CONTRIBUTOR",
  NO_RESPONSIBLE = "NO_RESPONSIBLE",
  RESPONSIBLE = "RESPONSIBLE",
}

export enum VendorDocumentType {
  PROFILE_IMG = "PROFILE_IMG",
  RAW_BANK_CERTIFICATE = "RAW_BANK_CERTIFICATE",
  RAW_CHAMBER_OF_COMMERCE = "RAW_CHAMBER_OF_COMMERCE",
  RAW_DOCUMENT_ID = "RAW_DOCUMENT_ID",
  RAW_RUT = "RAW_RUT",
}

export enum accountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}

export enum increaseReason {
  cash_flow_needs = "cash_flow_needs",
  increase_pricing_import = "increase_pricing_import",
  low_inventory = "low_inventory",
  margin_increase = "margin_increase",
  other = "other",
}

export enum GuaranteeType {
  Dias = "daily",
  Meses = "monthly"
}

export enum ProductVariantName {
  clothing_size_adults = "clothing_size_adults",
  clothing_size_kids = "clothing_size_kids",
  color = "color",
  shoe_size_adults_us = "shoe_size_adults_us",
  shoe_size_adults_eu = "shoe_size_adults_eu",
  shoe_size_adults_mx = "shoe_size_adults_mx",
  shoe_size_kids = "shoe_size_kids",
  shoe_size_baby = "shoe_size_baby",
  ram_memory = "ram_memory",
  material = "material",
  bed_sheets_size_co = "bed_sheets_size_co",
  bed_sheets_size_mx = "bed_sheets_size_mx",
  jewels_material = "jewels_material",
  jewels_size = "jewels_size",
  jewels_diameter = "jewels_diameter",
  size = "size",
}

interface ProductGuaranteeInput {
  type?: string;
  value?: number;
  description?: string;
}
export interface AddressInput {
  streetAddress: string;
  extraAddress?: string | null;
  innerNumber?: string | null;
  outsideNumber?: string | null;
  zipCode?: string | null;
  colony?: string | null;
  delegation?: string | null;
  city?: string | null;
  cityId?: number | null;
  stateShortCode?: string | null;
  stateId?: number | null;
  country?: string | null;
}

export interface BankInfoInput {
  bankId?: number | null;
  accountType?: accountType | null;
  accountNumber?: string | null;
  accountNumberBank?: string | null;
}

export enum PasswordResetError {
  CODE_INVALID = "CODE_INVALID",
  CODE_TIME_OUT = "CODE_TIME_OUT",
  PASSWORD_TOO_SHORT = "PASSWORD_TOO_SHORT",
  UNKNOWN_PHONE_NUMBER = "UNKNOWN_PHONE_NUMBER",
}

export interface BrandInput {
  brandName: string;
  description: string;
  defaultProductCommission?: number | null;
  website?: string | null;
}

export interface IncreaseProductReasonInput {
  increaseReason: increaseReason;
  increaseReasonOther?: string | null;
  increaseComments?: string | null;
}

export interface ProductImageInput {
  productId: number;
  name: string;
  imageBase64: string;
  imageExtension: string;
  sortOrder: number;
}

export interface ProductInput {
  id?: string;
  auxId?: string;
  isActive?: boolean;
  productName?: string;
  description?: string;
  brandId?: number;
  sku?: string;
  inventory?: number;
  basePrice?: number;
  categoryId?: number;
  guarantee?: string;
  guaranteeInput?: ProductGuaranteeInput | null;
  techReport?: string | null;
  elenasCommissionPercent?: number | null;
  duplicate?: boolean | null;
  duplicatedProductId?: number | null;
  parent_id?: number | null;
  variant_id?: number | null;
  productVariants?: (ProductInput | null)[] | null;
  variants?: (ProductVariantInput | null)[] | null;
  availableQuantity?: number;
  weight?: number | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  guidesNumber?: number | null;
  statusProductVariant?: string;
}

export interface ProductInventory {
  id: number;
  inventory: number;
  sku: string;
}

export interface ProductVariantDetail {
  id?: number;
  description: string;
}

export interface ProductVariantInput {
  name: ProductVariantName;
  details: ProductVariantDetail;
}

export interface VendorAccountingInput {
  personType?: PersonType | null;
  taxRegime?: TaxRegime | null;
  taxable: boolean;
  documentId?: string | null;
  documentType?: string | null;
  businessName?: string | null;
  retentionAgent?: boolean | null;
  fiscalCity?: string | null;
  accountingsMail?: string | null;
  commercialsMail?: string | null;
  bankInfo?: BankInfoInput | null;
  address?: AddressInput | null;
}

export interface VendorBasicInput {
  name: string;
  businessName?: string | null;
  taxable: boolean;
  website?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  secondLastName?: string | null;
  email?: string | null;
  cellphone?: string | null;
  address?: AddressInput | null;
  warehouseCityId?: number | null;
  warehouseStateId?: number | null;
  logoImgName?: string | null;
  logoImgBase64?: string | null;
  backgroundImgName?: string | null;
  backgroundImgBase64?: string | null;
}

export interface VendorDocumentInput {
  documentType: VendorDocumentType;
  documentBase64: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

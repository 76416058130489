import {Action} from "overmind";
import {
  AddNewVariant,
  OnAddition,
  OnDelete,
  OnDeleteVariant,
  SelectVariant,
  UploadImages,
} from "../effects/api/graphql-types/Products";

export const nextStep: Action<void> = ({ state }) => {
  state.activeStep++;
};

export const backStep: Action<void> = ({ state }) => {
  state.activeStep--;
};

export const updateTypeProduct: Action<boolean, void> = ({ state }, variables) => {
  state.isProductSimple = variables;
};

export const updateImages: Action<UploadImages, void> = ({ state }, { file, typeFile }) => {
  switch (typeFile) {
    case "main":
      state.productVariants.images!.mainImage = {
        ...file,
        name: file.name,
        imageExtension: file.name.split('.').pop(),
        type: typeFile
      }
      break;
    case "collection":
      state.productVariants.images!.stockImages = [...state.productVariants.images!.stockImages, {
        ...file,
        name: file.name,
        imageExtension: file.name.split('.').pop(),
        type: typeFile
      }];
      break;
    case "own":
      state.productVariants.images!.ownImages = [...state.productVariants.images!.ownImages, {
        ...file,
        name: file.name,
        imageExtension: file.name.split('.').pop(),
        type: typeFile
      }];
      break;
    default:
      state.productVariants!.images = {
        mainImage: {} as File,
        stockImages: [],
        ownImages: []
      }
      break;
  }
};

export const deleteImagesToState: Action<UploadImages, void> = ({ state }, { file, typeFile }) => {

  if (file.id) {
    state.productVariants.imagesToDelete = [...state.productVariants.imagesToDelete, {
      ...file,
      id: file.id,
      name: file.name,
    }]
  }

  switch (typeFile) {
    case "main":
      state.productVariants.images!.mainImage = {} as File
      break;
    case "collection":
      const auxStockImages = [
        ...state.productVariants.images!.stockImages.filter((item) => item.name !== file.name)
      ]
      state.productVariants.images!.stockImages = auxStockImages;
      break;
    case "own":
      const auxOwnImage = [
        ...state.productVariants.images!.ownImages.filter((item) => item.name !== file.name)
      ]
      state.productVariants.images!.ownImages = auxOwnImage;
      break;
    default:
      state.productVariants!.images = {
        mainImage: {} as File,
        stockImages: [],
        ownImages: []
      }
      break;
  }
};

export const updateAddNewVariantInit: Action<AddNewVariant[], void> = ({ state }, variant) => {
  state.productVariants.detail!.variants = variant;
};

export const updateAddNewVariant: Action<AddNewVariant, void> = ({ state }, variables) => {
  state.productVariants.detail!.variants = [...state.productVariants.detail!.variants!, { ...variables }]
};

export const updateSelectVariant: Action<SelectVariant, void> = ({ state }, { name, uuid }) => {
  const variant = state.productVariantValues.find(el => el.name === name);
  state.productVariants.detail!.variants = [
    ...state.productVariants.detail!.variants!.map((el) => {
      if (el.id === uuid) {
        return {
          ...el,
          type: variant!,
        }
      }
      return {...el}
    })
  ]
};

 export const updateOnAddition: Action<OnAddition, void> = ({ state }, { newTag, uuid }) => {
  state.productVariants.detail!.variants = [
    ...state.productVariants.detail!.variants!.map((el) => {
      if (el.id === uuid) {
        newTag.statusTag = "new";
        return {
          ...el,
          tags: [...el.tags, newTag],
        }
      }
      return {...el}
    })
  ]
}; 

export const updateOnDelete: Action<OnDelete, void> = ({ state }, { indexTag, uuidVariant }) => {
  state.productVariants.detail!.variants = [
    ...state.productVariants.detail!.variants!.map((variantIt) => {
      if (variantIt.id === uuidVariant) {
        const tags = variantIt.tags.map((tag, idx) => {
          if (idx === indexTag) {
            return { ...tag, statusTag: "delete" };
          }
          return {
            ...tag,
          };
        });
        variantIt.tags = tags;
      }
      return { ...variantIt };
    }),
  ];
};

export const updateOnDeleteVariant: Action<OnDeleteVariant, void> = ({ state }, { uuid }) => {
  state.productVariants.detail!.variants = [...state.productVariants.detail!.variants!.filter((variantIt) => variantIt.id !== uuid)]
};

export const updateStateToEditProduct: Action<boolean, void> = ({ state }, variables ) => {
  state.activeStep = 1;
  state.isProductSimple = variables;
};

export const clearDataToCreateProduct: Action<void> = ({ state }) => {
  state.createProduct = {
    auxId: "",
    isActive: false,
    productName: "",
    description: "",
    brandId: 0,
    sku: "",
    inventory: 0,
    basePrice: 0,
    categoryId: 0,
    guarantee: "",
    guaranteeInput: null,
    techReport: null,
    elenasCommissionPercent: null,
    duplicate: null,
    duplicatedProductId: null,
    parent_id: null,
    variant_id: null,
    productVariants: null,
    variants: null,
    weight: 0,
    height: 0,
    length: 0,
    width: 0,
    guidesNumber: 1,
  };
  state.activeStep = 0;
  state.productVariants.images = {
    mainImage: {} as File,
    stockImages: [],
    ownImages: [],
  };
  state.productVariants.imagesToDelete = [];
  state.productVariants.detail = {
    variants: [],
  };
};

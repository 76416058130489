import { gql, Query } from "../../../overmind-graphql/src";
import { Brands } from "./graphql-types/Brands";
import { Categories } from "./graphql-types/Categories";
import { Cities } from "./graphql-types/Cities";
import {
  CommunicationAlerts,
  CommunicationAlertsVariables,
} from "./graphql-types/CommunicationAlerts";
import {
  configurations,
  configurationsVariables,
} from "./graphql-types/configurations";
import {
  searchLocationByZipCode,
  searchLocationByZipCodeVariables,
} from "./graphql-types/searchLocationByZipCode";
import {
  fetchProductReviews,
  fetchProductReviewsVariables,
} from "./graphql-types/fetchProductReviews";
import { fetchProductReviewsAvg } from "./graphql-types/fetchProductReviewsAvg";
import { InventoryAlerts } from "./graphql-types/InventoryAlerts";
import { OrderAlerts, OrderAlertsVariables } from "./graphql-types/OrderAlerts";
import { OrderDetails } from "./graphql-types/OrderDetails";
import {
  GenerateOrdersReportVariables,
  MassiveGuideDownloadVariables,
  MassiveGuideListVariables,
  Orders,
  OrdersVariables,
} from "./graphql-types/Orders";
import {
  pendingOrderEarnings,
  pendingOrderEarningsVariables,
} from "./graphql-types/pendingOrderEarnings";
import { Products, ProductsVariables } from "./graphql-types/Products";
import { ProductsForInventory } from "./graphql-types/ProductsForInventory";
import {
  receivedPayments,
  receivedPaymentsVariables,
} from "./graphql-types/receivedPayments";
import { SalesSumary } from "./graphql-types/SalesSumary";
import {
  sendAccountingReports,
  sendAccountingReportsVariables,
} from "./graphql-types/sendAccountingReports";
import { states } from "./graphql-types/states";
import { banks } from "./graphql-types/banks";
import { TopClients } from "./graphql-types/TopClients";
import {
  upcomingPayment,
  upcomingPaymentVariables,
} from "./graphql-types/upcomingPayment";
import { VendorInfo } from "./graphql-types/VendorInfo";
import {
  vendorPaidOrders,
  vendorPaidOrdersVariables,
} from "./graphql-types/vendorPaidOrders";
import { fetchReports } from "./graphql-types/fetchReports";
import { variants } from "./graphql-types/Variants";
import { vendorMedals } from "./graphql-types/vendorMedals";

export const getVendorMedals: Query<vendorMedals> = gql`
  query vendorMedals {
    vendorMedals {
      title
      dateLock
      name
      lock
      key
      warning
      RangeValues {
        min
        max
        value
        valueFloat
      }
      condition
    }
    effectiveOrders {
      total
      requirementOrders
      requirementDays
    }
  }
` as any;

export const getVendorInfo: Query<VendorInfo> = gql`
  query    {
    vendorInfo {
      id
      countMedalsMktp
      totalMedalsMktp
      logoImgUrl
      backgroundImgUrl
      statusMedalsMktp
      businessName
      documentId
      retentionAgent
      fiscalCity
      accountingsMail
      commercialsMail
      rawDocumentId
      rawRut
      rawBankCertificate
      rawChamberOfCommerce
      profileImg
      personType
      taxRegime
      taxable
      website
      regimeSetted
      productAlertsEnable
      commission
      metabaseUrl
      integratedVendor
      warehouseAddress
      warehouseCity {
        id
      }
      warehouseState {
        id
      }
      bankInfo {
        bankId
        accountType
        accountNumber
        accountNumberBank
      }
      user {
        id
        firstName
        lastName
        secondLastName
        cellphone
        email
        documentType
        address {
          streetAddress
          innerNumber
          outsideNumber
          zipCode
          colony
          state
          city
          extraAddress
          delegation
        }
      }
      address {
        streetAddress
        innerNumber
        outsideNumber
        zipCode
        colony
        state
        city
        extraAddress
        delegation
      }
      mainBrand {
        id
        name
        description
        defaultProductCommission
        website
      }
      elenasCommissionRules {
        elenasCommission
        priority
        category {
          id
        }
        upperAmountLimit
        lowerAmountLimit
      }
      colony
      delegation
      auditedInformation
      commissionRuleVariable {
        elenasCommission
        upperAmountLimit
        lowerAmountLimit
      }
    }
  }
` as any;

export const getSalesSumary: Query<SalesSumary> = gql`
  query SalesSumary {
    salesSumary
  }
` as any;

export const getDepartments: Query<states> = gql`
  query states {
    states {
      id
      displayName
      shortCode
      cities {
        id
        displayName
      }
    }
  }
` as any;

export const getTopClients: Query<TopClients> = gql`
  query TopClients {
    topClients {
      total
      client {
        id
        firstName
        lastName
      }
    }
  }
` as any;

export const getOrders: Query<Orders, OrdersVariables> = gql`
  query Orders(
    $orderStatus: String
    $orderId: Int
    $trackingNumber: String
    $paymentStatus: PaymentStatus
    $page: Int
  ) {
    orders(
      orderStatus: $orderStatus
      orderId: $orderId
      trackingNumber: $trackingNumber
      paymentStatus: $paymentStatus
      page: $page
      perPage: 20
    ) {
      currentPage
      totalPages
      totalResults
      resultsPerPage
      results {
        id
        comments
        createDate
        number
        trackingNumber
        logisticProvider
        client {
          id
          firstName
          lastName
        }
        newClientPrice
        clientPrice
        newEarnings
        earnings
        newTotal
        total
        newTax
        tax
        vendorDiscount
        elenasDiscount
        newShipping
        shipping
        sku
        sellerPaid
        currentStatus {
          id
          status
          date
          reason
        }
        currentNewStatus {
          id
          status
          date
          reason
        }
        paymentStatus
        seller {
          user {
            id
            firstName
            lastName
            cedula
            cellphone
            email
            address {
              streetAddress
              city
            }
          }
        }
        guia
        cartItems {
          id
          newBasePrice
          basePrice
          quantity
          newShippingCost
          shippingCost
          newSubTotal
          subTotal
          newEarning
          earning
          newClientPrice
          clientPrice
          product {
            id
            name
            description
            newBasePrice
            basePrice
            availableQuantity
            weight
            dimensions
            guarantee
            sku
            image {
              id
              url
            }
            variants {
              name
              details {
                id
                description
              }
            }
          }
        }
      }
    }
  }
` as any;

export const getProducts: Query<Products, ProductsVariables> = gql`
  query Products(
    $name: String
    $sku: String
    $page: Int
    $visible: Boolean
    $orderByInventory: SortDirection
  ) {
    products(
      name: $name
      sku: $sku
      page: $page
      perPage: 10
      visible: $visible
      orderByInventory: $orderByInventory
    ) {
      currentPage
      totalPages
      totalResults
      resultsPerPage
      results {
        id
        name
        availableQuantity
        visible
        description
        guarantee
        guaranteeDetail {
          type
          value
          description
        }
        dimensions
        weight
        height
        length
        width
        guidesNumber
        basePrice
        newBasePrice
        sku
        techReport
        elenasCommissionPercent
        category {
          id
          name
        }
        image {
          id
          url
          type
        }
        images {
          id
          url
          type
        }
        brand {
          id
          name
        }
        reviewSummary {
          avg
        }
        discount {
          newBasePriceWithoutDiscount
          discountPercent
          name
        }
        productVariants {
          id
          sku
          availableQuantity
          newBasePrice
          visible
          variants {
            name
            details {
              description
            }
          }
        }
      }
    }
  }
` as any;

export const getProductInventory: Query<ProductsForInventory> = gql`
  query ProductsForInventory {
    productsForInventory {
      id
      name
      sku
      quantity
      visible
    }
  }
` as any;

export const fetchProductReviewsVendorsQ: Query<
  fetchProductReviews,
  fetchProductReviewsVariables
> = gql`
  query fetchProductReviewsVendors(
    $top: Boolean
    $productId: Int
    $page: Int!
    $perPage: Int!
  ) {
    productReviews(
      top: $top
      productId: $productId
      page: $page
      perPage: $perPage
    ) {
      results {
        id
        text
        rating
        liked
        usefulCount
        images {
          id
          url
          sortOrder
        }
        reviewer {
          firstName
          lastName
        }
        product {
          id
          name
          image {
            url
          }
        }
      }
      currentPage
      totalPages
      resultsPerPage
    }
  }
` as any;

export const fetchProductReviewsAvgQ: Query<fetchProductReviewsAvg, void> = gql`
  query fetchProductReviewsAvg {
    reviewAvgRating
  }
` as any;

export const getOrdersDetails: Query<OrderDetails> = gql`
  query OrderDetails {
    orderDetails {
      id
      date
      guia
      logisticProvider
      productName
      comments
      quantity
      status
      sku
      basePrice
      earnings
      seller
      identification
      address
      city
      phone
      email
    }
  }
` as any;

export const getCities: Query<Cities> = gql`
  query Cities {
    cities {
      id
      name
      displayName
    }
  }
` as any;

export const getBrands: Query<Brands> = gql`
  query Brands {
    brands {
      id
      name
      description
      defaultProductCommission
      website
    }
  }
` as any;

export const getCategories: Query<Categories> = gql`
  query Categories {
    categories {
      id
      name
      parentId
    }
  }
` as any;

export const getInventoryAlerts: Query<InventoryAlerts> = gql`
  query InventoryAlerts {
    inventoryAlerts {
      id
      createdAt
      alertType
      message
      product {
        id
        name
        availableQuantity
        visible
        description
        basePrice
        newBasePrice
        guarantee
        dimensions
        weight
        sku
        techReport
        elenasCommissionPercent
        category {
          id
          name
        }
        image {
          id
          url
        }
        images {
          id
          url
        }
        brand {
          id
          name
        }
      }
    }
  }
` as any;

export const getOrderAlerts: Query<OrderAlerts, OrderAlertsVariables> = gql`
  query OrderAlerts(
    $lateOrders: String
    $dangerOrders: String
    $newOrders: String
    $page: Int
  ) {
    orderAlerts(
      lateOrders: $lateOrders
      dangerOrders: $dangerOrders
      newOrders: $newOrders
      page: $page
      perPage: 20
    ) {
      currentPage
      totalPages
      totalResults
      resultsPerPage
      totalNewOrders
      totalLateOrders
      totalDangerOrders
      results {
        product
        createdAt
        alertType
        order {
          id
          comments
          createDate
          number
          trackingNumber
          logisticProvider
          client {
            id
            firstName
            lastName
          }
          newClientPrice
          clientPrice
          newEarnings
          earnings
          newTotal
          total
          newTax
          tax
          sku
          newShipping
          shipping
          sellerPaid
          currentNewStatus {
            id
            status
            date
            reason
          }
          paymentStatus
          seller {
            user {
              id
              firstName
              lastName
              cedula
              cellphone
              email
              address {
                streetAddress
                city
              }
            }
          }
          guia
          cartItems {
            id
            newBasePrice
            basePrice
            quantity
            newShippingCost
            shippingCost
            newSubTotal
            subTotal
            newEarning
            earning
            newClientPrice
            clientPrice
            product {
              id
              name
              description
              newBasePrice
              basePrice
              availableQuantity
              weight
              dimensions
              guarantee
              image {
                id
                url
              }
              variants {
                name
                details {
                  id
                  description
                }
              }
            }
          }
        }
      }
    }
  }
` as any;

export const getCommunicationAlerts: Query<
  CommunicationAlerts,
  CommunicationAlertsVariables
> = gql`
  query CommunicationAlerts($page: Int, $perPage: Int) {
    communicationAlerts(page: $page, perPage: $perPage) {
      currentPage
      totalPages
      totalResults
      resultsPerPage
      results {
        id
        createdAt
        category
        title
        description
        seen
      }
    }
  }
` as any;

/**
 * getUpcomingPaymentQ
 */
export const getUpcomingPaymentQ: Query<
  upcomingPayment,
  upcomingPaymentVariables
> = gql`
  query upcomingPayment($page: Int, $perPage: Int) {
    upcomingPayment(page: $page, perPage: $perPage) {
      total
      data {
        currentPage
        totalPages
        totalResults
        resultsPerPage
        results {
          id
          currentStatus {
            status
          }
        }
      }
    }
  }
` as any;

/**
 * pendingOrderEarningsQ
 */
export const getPendingOrderEarningsQ: Query<
  pendingOrderEarnings,
  pendingOrderEarningsVariables
> = gql`
  query pendingOrderEarnings($page: Int, $perPage: Int) {
    pendingOrderEarnings(page: $page, perPage: $perPage) {
      total
      data {
        currentPage
        totalPages
        totalResults
        resultsPerPage
        results {
          id
          currentStatus {
            status
          }
        }
      }
    }
  }
` as any;

/**
 * receivedPaymentsQ
 */
export const getReceivedPaymentsQ: Query<
  receivedPayments,
  receivedPaymentsVariables
> = gql`
  query receivedPayments($page: Int, $perPage: Int) {
    receivedPayments(page: $page, perPage: $perPage) {
      currentPage
      totalPages
      totalResults
      resultsPerPage
      results {
        id
        invoiceNumber
        accountingCutDate
        paymentDate
        totalPaid
      }
    }
  }
` as any;

/**
 * vendorPaidOrdersQ
 */
export const getVendorPaidOrdersQ: Query<
  vendorPaidOrders,
  vendorPaidOrdersVariables
> = gql`
  query vendorPaidOrders($page: Int, $perPage: Int) {
    vendorPaidOrders(page: $page, perPage: $perPage) {
      currentPage
      totalPages
      totalResults
      resultsPerPage
      results {
        id
        paymentDate
        totalPaid
        date
        invoiceNumber
        product
      }
    }
  }
` as any;

/**
 * sendAccountingReportsQ
 */
export const sendAccountingReportsQ: Query<
  sendAccountingReports,
  sendAccountingReportsVariables
> = gql`
  query sendAccountingReports($date: [Date!]!) {
    sendAccountingReports(date: $date) {
      response
      recipient
    }
  }
` as any;

export const configurationsQ: Query<configurations, configurationsVariables> =
  gql`
    query configurations($keys: [ConfigurationKey!]!) {
      configurations(keys: $keys) {
        key
        value
      }
    }
  ` as any;

export const searchLocationByZipCodeQ: Query<
  searchLocationByZipCode,
  searchLocationByZipCodeVariables
> = gql`
  query searchLocationByZipCode($zip_code: Int!) {
    searchLocationByZipCode(zip_code: $zip_code) {
      __typename
      ... on LocationSuggestions {
        zip_code
        state
        stateId
        stateShortCode
        city
        cityId
        delegation
        colonies
        country
      }
      ... on ValidationErrors {
        message
        errors {
          message
        }
      }
    }
  }
` as any;

export const getBanks: Query<banks> = gql`
  query banks {
    fetchBanks {
      id
      name
      countryCode
    }
  }
` as any;

export const getReports: Query<fetchReports> = gql`
  query fetchReports {
    fetchReports {
      file_url
      vendor_id
      accounting_cut_date
    }
  }
` as any;

export const getMassiveGuideDownload: Query<
  String,
  MassiveGuideDownloadVariables
> = gql`
  query MassiveGuideDownload($orders_list: [String]!) {
    massiveGuideDownload(orders_list: $orders_list) {
      result
      error {
        message
      }
    }
  }
`;

export const getMassiveGuideDownloadList: Query<
  String,
  MassiveGuideListVariables
> = gql`
  query MassiveGuideDownloadList($report_type: VendorReportType!) {
    massiveGuideDownloadList(report_type: $report_type) {
      s3Url
      createdAt
      available
    }
  }
`;

export const generateOrdersReport: Query<
  String,
  GenerateOrdersReportVariables
> = gql`
  query GenerateOrdersReport(
    $days: Int!
    $orderId: Int
    $trackingNumber: String
    $orderStatus: String
    $paymentStatus: PaymentStatus
  ) {
    generateOrdersReport(
      days: $days
      orderId: $orderId
      trackingNumber: $trackingNumber
      orderStatus: $orderStatus
      paymentStatus: $paymentStatus
    ) {
      result
      error {
        message
      }
    }
  }
`;

export const getContracts: Query<any> = gql`
  query Contracts {
    contract {
      id
      name
      contract_file_url
    }
  }
` as any;

export const getProductVariantValues: Query<variants> = gql`
  query variants {
    productVariantValues {
      name
      label
      values
      enabled
    }
  }
`;
